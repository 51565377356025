<template>
    <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
            <v-row class="mt-2">
                <v-col cols="12">
                    <project-info-card
                        v-if="advice.id"
                        :project="advice"
                        type="advice"
                        card-width="100%"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="540"
                    >
                    </project-info-card>
                    <div class="mt-10">
                        <v-alert
                            text
                            color="accent darken-1"
                            icon="fa-solid fa-kitchen-set"
                            border="left"
                        >
                            {{ advice.description }}
                        </v-alert>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getAdvice } from "@/api/nutritionApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import Requirements from "@/components/General/Requirements.vue";
import ViewStepper from "@/components/General/ViewStepper.vue";
export default {
    name: "AdviceInfo",

    components: {
        ProjectInfoCard,
        Requirements,
        ViewStepper,
        ViewStepsBtn: () => import(`@/components/General/ViewStepsBtn.vue`),
    },

    data() {
        return {
            stepper: 1,
            advice: {},
            cardHeight: CONFIG.card_heigth,
            mediaHeight: CONFIG.media_height,
        };
    },

    computed: {
        steps() {
            return this.recipe.steps.length;
        },
        hasIngredients() {
            return this.recipe?.ingredients ? true : false;
        },
        hasSteps() {
            return this.recipe?.steps?.length > 0 ? true : false;
        },
    },

    methods: {
        async handleAdvice() {
            const payload = {
                id: this.$route.params.id,
            };
            const { response, error } = await withAsync(getAdvice, payload);

            if (error) {
                return;
            }

            this.advice = response.data.data;
        },
    },

    created() {
        this.handleAdvice();
    },
};
</script>

<style lang="scss" scoped></style>
