import { api } from "./api";

const URLS = {
    recipes: "recipes",
    recipe: "recipe",
    advices: "advices",
    advice: "advice",
    editAdvice: "admin/advice",
};

export const getAllRecipes = () => {
    return api.get(URLS.recipes);
};

export const getAllAdvices = () => {
    return api.get(URLS.advices);
};

export const getRecipe = (payload) => {
    return api.get(`${URLS.recipe}/${payload.id}`);
};

export const editRecipe = (payload, url) => {
    return api.post(url, payload);
};

export const getAdvice = (payload) => {
    return api.get(`${URLS.advice}/${payload.id}`);
};

export const editAdvice = (payload, url) => {
    return api.post(url, payload);
};
